<template>
  <b-row
      v-if="show"
      class="content-header"
  >

    <!-- Content Left -->
    <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            <span v-if="$route.meta.breadcrumbTitle">{{ $i18n.t($route.meta.breadcrumbTitle) }}</span>
            <span v-else>{{ $i18n.t($route.meta.pageTitle) }}</span>
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                  v-for="item in $route.meta.breadcrumb"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
              >
                <span v-if="item.withoutTranslate">{{ item.text }}</span>
                <span v-else>{{ $i18n.t(item.text) }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                  v-for="item in items"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
              >
                <span v-if="item.withoutTranslate">{{ item.text }}</span>
                <span v-else>{{ $i18n.t(item.text) }}</span>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {BBreadcrumb, BBreadcrumbItem, BCol, BRow,} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
  },
  props: {
    items: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
  },
  computed: {
    show(){
      return ((!this.$route.meta.hideDefaultBreadcrumb) && (this.$route.meta.breadcrumb || this.$route.meta.pageTitle)) || this.items.length > 0
    }
  },
}
</script>

<template>
  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isModalSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-modal-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $i18n.t('Add new record') }}
        </h5>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(store)"
            @reset.prevent="resetForm"
        >

          <!-- Platform -->
          <validation-provider
              #default="{ errors }"
              name="platform"
          >
            <b-form-group
                :label="$i18n.t('platform')"
                label-for="platform"
            >
              <v-select
                  v-model="form.platform"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="platforms"
                  :reduce="val => val"
                  :clearable="false"
                  input-id="platform"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Year -->
          <validation-provider
              #default="{ errors }"
              name="year"
          >
            <b-form-group
                :label="$i18n.t('year')"
                label-for="year"
            >
              <v-select
                  v-model="form.year"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="years"
                  :reduce="val => val"
                  :clearable="false"
                  input-id="year"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Image -->
          <validation-provider
              #default="{ errors }"
              name="image"
          >
            <b-form-group
                :label="$i18n.t('image')"
                label-for="image"
            >
              <b-form-file
                  id="image"
                  ref="image"
                  v-model="form.image"
                  accept="image/*"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
            >
              <span>{{ $i18n.t('Add') }}</span>
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
            >
              {{ $i18n.t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>

import {required} from '@validations'
import Ripple from 'vue-ripple-directive'
import ReportService from "@/views/reports/services/MarketingPlanerService";

export default {
  emits: ['is-modal-sidebar-active', 'refetch-data'],
  directives: {
    Ripple,
  },
  model: {
    prop: 'isModalSidebarActive',
    event: 'update:is-modal-sidebar-active',
  },
  props: {
    isModalSidebarActive: {
      type: Boolean,
      required: true,
    },
    platforms: {
      type: Array,
      required: true,
    },
    years: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      required,
      form: {},
      blankForm: {
        year: '',
        platform: '',
        image: null
      },
      loading: false,
    }
  },
  mounted() {
    this.resetForm()
  },
  methods: {
    resetForm() {
      this.form = JSON.parse(JSON.stringify(this.blankForm))
      this.$refs.refFormObserver.reset()
    },
    store() {
      if (!this.loading) {
        this.loading = true
        const image = this.$refs.image.files[0];
        const formData = new FormData();
        formData.append('year', this.form.year);
        formData.append('platform', this.form.platform);
        formData.append('image', image);
        ReportService.store(formData).then(response => {
          this.loading = false
          this.$emit('refetch-data')
          this.$emit('update:is-modal-sidebar-active', false)
          this.resetForm()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('Success'),
              icon: 'EditIcon',
              variant: 'success',
              text: response.data.message
            },
          })
        }).catch(error => {
          this.loading = false
          if (error.data && error.data.errors) {
            this.$refs.refFormObserver.setErrors(error.data.errors)
          }
        })
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isModalSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-modal-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$i18n.t('Add new record'))+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.store)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"platform"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$i18n.t('platform'),"label-for":"platform"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.platforms,"reduce":function (val) { return val; },"clearable":false,"input-id":"platform"},model:{value:(_vm.form.platform),callback:function ($$v) {_vm.$set(_vm.form, "platform", $$v)},expression:"form.platform"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"year"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$i18n.t('year'),"label-for":"year"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.years,"reduce":function (val) { return val; },"clearable":false,"input-id":"year"},model:{value:(_vm.form.year),callback:function ($$v) {_vm.$set(_vm.form, "year", $$v)},expression:"form.year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$i18n.t('image'),"label-for":"image"}},[_c('b-form-file',{ref:"image",attrs:{"id":"image","accept":"image/*"},model:{value:(_vm.form.image),callback:function ($$v) {_vm.$set(_vm.form, "image", $$v)},expression:"form.image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t('Add')))])]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$i18n.t('Cancel'))+" ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
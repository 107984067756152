import axios from '@axios'

export default class ReportDataService {
    apiUrl;

    charts;

    filter;

    getAll(params) {
        return axios.get(this.apiUrl, {params});
    }

    get(id, params) {
        return axios.get(`${this.apiUrl}/${id}`, {params});
    }

    export(id = '', params, config = {responseType: 'blob'}) {
        return axios.get(`${this.apiUrl}/export/${id}`, {...{params}, ...config});
    }

    getChartData(id = '', params) {
        return axios.get(`${this.apiUrl}/chart/${id}`, {params});
    }

    setFilter(filter) {
        this.filter = filter
    }

    // eslint-disable-next-line class-methods-use-this
    getChartId() {
        return ''
    }

    getChartParams() {
        return this.filter;
    }

    // eslint-disable-next-line class-methods-use-this
    handleChartResponse(response) {
        return response.data.data
    }

    getCharts() {
        return this.charts.filter(chart => chart.isActive === true);
    }

    // eslint-disable-next-line class-methods-use-this
    handleConstraint(params) {

    }
}

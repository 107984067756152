<template>
  <div>
    <app-breadcrumb
        :items="breadcrumbs"
    />
    <form-add-new-object
        v-if="isEnableAddAction"
        ref="form"
        :platforms="platforms"
        :years="years"
        :is-modal-sidebar-active.sync="isModalSidebarActive"
        @refetch-data="getData"
    />
    <loading :show="loading">
      <b-card no-body>
        <b-row>
          <b-col
              cols="11"
              md="11"
          >
            <filter-report
                ref="filter"
                :filter.sync="filter"
                :enable="!loading"
                show-platforms-filter
                show-year-filter
            />
          </b-col>
          <b-col
              cols="1"
              md="1"
              class="d-flex align-items-center"
          >
            <b-button
                v-if="isEnableAddAction"
                variant="success"
                @click="isModalSidebarActive = true;"
            >
              +
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <card-sec
          v-for="object in data"
          :key="object.id"
          :object="object"
          :loading.sync="loading"
          :is-enable-action="can('marketing-planer','delete')"
          @fetch-data="getData"
      />
    </loading>
  </div>
</template>
<script>
import AppBreadcrumb from "@/layouts/components/AppBreadcrumb.vue";
import FilterReport from "@/views/reports/components/FilterReports.vue";
import ReportService from "@/views/reports/services/MarketingPlanerService";
import CardSec from "@/views/reports/marketing-planer/Card.vue";
import FormAddNewObject from "@/views/reports/marketing-planer/Form.vue";

export default {
  components: {
    AppBreadcrumb,
    FilterReport,
    CardSec,
    FormAddNewObject,
  },
  data() {
    return {
      filter: null,
      loading: false,
      data: [],
      isModalSidebarActive: false,
      platforms: [],
      years: [],
    }
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: 'reports.names.marketing-planer',
          active: true,
        }
      ]
    },
    isEnableAddAction(){
      return this.can('marketing-planer','create');
    }
  },
  watch: {
    filter(_new, _old) {
      if (!_.isEqual(_new, _old)) {
        this.getData()
      }
    },
  },
  mounted() {
    if(this.isEnableAddAction) { this.getUtils() }
  },
  methods: {
    getData() {
      this.loading = true
      ReportService.getAll(this.filter).then(res => {
        this.data = res.data.data
        this.loading = false
      })
    },
    getUtils() {
      this.$http.get('utils/marketing-planer')
          .then(res => {
            this.platforms = res.data.data.platforms
            this.years = this.listYears()
          })
    },
  },
}

</script>

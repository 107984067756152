<template>
  <b-card
      class="mb-4"
      no-body
  >
    <b-card-header>
      <h4> {{ name }}</h4>
    </b-card-header>
    <b-card-body>
      <img
          :src="object.path"
          :alt="name"
          class="image w-100"
      >
      <b-button
          v-if="isEnableAction"
          class="float-md-right mt-1"
          variant="danger"
          @click="deleteObject"
      >
        {{ $i18n.t('Delete') }}
      </b-button>
    </b-card-body>
  </b-card>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ReportService from "@/views/reports/services/MarketingPlanerService";

export default {
  components: {},
  props: {
    object: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isEnableAction: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    name() {
      return `${this.object.platform} // ${this.object.year}`
    }
  },
  methods: {
    deleteObject() {
      if(!this.loading){
        this.$emit('loading',true)
        ReportService.delete(this.object.id).then(response => {
          this.$emit('loading',false)
          this.$emit('fetch-data')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('Success'),
              icon: 'EditIcon',
              variant: 'success',
              text: response.data.message
            },
          })
        }).catch(error => {
          this.$emit('update:loading',false)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'TrashIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        })
      }
    }
  }
}

</script>

import axios from '@axios'
import ReportDataService from "./ReportDataService";

class MarketingPlanerDataService extends ReportDataService {
    apiUrl = 'reports/marketing-planer';

    store(params) {
        return axios.post(this.apiUrl, params);
    }

    delete(id) {
        return axios.delete(`${this.apiUrl}/${id}`);
    }
}

export default new MarketingPlanerDataService();
